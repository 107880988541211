<template>
  <div id="content" class="content">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title text-center m-t-20">Sign up</h4>
        <h6 class="card-subtitle m-b-20 text-center" style="margin-right: 29%">
          Already a member? <span class="ria-color"><a href="/magnolia/signin">Sign in now</a></span>
        </h6>
        <ValidationObserver v-slot="{ invalid }" ref="observer">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="form-group m-b-20">
              <label for="firstName" class="form-label">First Name</label>
              <ValidationProvider
                name="First Name"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  v-model="registerForm.firstName"
                  class="form-control form-control-lg"
                  id="firstName"
                  :class="classes"
                  required
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group m-b-20">
              <label for="lastName" class="form-label">Last Name</label>
              <ValidationProvider
                name="Last Name"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  v-model="registerForm.lastName"
                  class="form-control form-control-lg"
                  id="lastName"
                  :class="classes"
                  required
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group m-b-20">
              <label for="userId" class="form-label">Email</label>
              <ValidationProvider
                name="Email"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="email"
                  v-model="registerForm.email"
                  class="form-control form-control-lg"
                  id="userId"
                  :class="classes"
                  required
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group m-b-20">
              <label for="password" class="form-label">Password</label>
              <ValidationProvider
                vid="confirm"
                name="Password"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="password"
                  data-toggle="password"
                  data-placement="after"
                  id="password-indicator-default"
                  v-model="registerForm.password"
                  class="form-control form-control-lg"
                  name="password"
                  required
                  :class="classes"
                  style="border-right: none !important"
                />
                <div id="passwordStrengthDiv" class="is0 m-t-5"></div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <!-- <div class="form-group m-b-20" v-show="false">
              <label for="password" class="form-label">Confirm Password</label>
              <ValidationProvider
                name="Confirm Password"
                :rules="{ required: true, confirmed: registerForm.password }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="password"
                  data-toggle="password"
                  data-placement="after"
                  class="form-control form-control-lg"
                  id="confirmPassword"
                  required
                  :class="classes"
                  style="border-right: none !important"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div> -->

            <div class="form-group m-b-20">
              <img :src="captchaImg" alt="" />
              <i
                class="fa fa-redo"
                style="font-size: 20px; margin-left: 2%; cursor: pointer"
                @click="refreshCaptcha()"
              ></i>
            </div>

            <div class="form-group m-b-20">
              <label for="captcha" class="form-label"
                >Enter characters above</label
              >
              <ValidationProvider
                name="characters"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  v-model="registerForm.captcha"
                  class="form-control form-control-lg"
                  id="captcha"
                  required
                  :class="classes"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <button
              type="button"
              class="btn"
              :disabled="invalid"
              :class="invalid ? 'login-btn-disable' : 'login-btn-enable'"
              @click="login()"
            >
              <i class="" id="loginBtnLbl"></i> &nbsp;&nbsp; Sign Up
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- end login-content -->

    <QrModel :qrCode="qrCode" :email="registerForm.email" type="signup" ></QrModel>
   
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService";
import Constant from "../plugin/Constant";
import Utils from "../plugin/Utils";
import QrModel from "../components/QrModel.vue";

function initialState (){
  return {
    registerForm: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      captcha: "",
    },
    captchaImg: "",
    loadingClass: "fa fa-spinner fa-spin",
    loginBtn: "loginBtnLbl",
    qrCode : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAAFeAQAAAADlUEq3AAADTklEQVR4Xu2ZUY7bMAwFCfgAOZKv7iPlAAK0nKGSeFu02J8ChWnCyCrW0B8Pj6ScjfnzGPHrnb/EDZ/jhs9xw+e44XP8Y/gZGdtzj8fMa3vGyEXsLo6Z94m9DZwLmMri6zM29liQtZgucAkVCjVULyMXyQ+3toYwW5FXaZWmmvOo3HbwPJJBsbCOHodKst8N9k5qdSgg0tVirRfTBPbmc//TZVIbeEVWkF56oFUyU3KAVfSAHT2F4aV5INVD8ySfUJbYSeeLw/QWJMIzHEsydkssb9ptgqw+8FA0jKSMyaSvWKPnwXOkesCoVJ+nC9FQzyMK6jWB69zuVYsJOTeaLdf2bRxfH5anveQ1lQuA7b1ufox0edjAM77RJDAwD2c2mm2K+b0GLw7badnCNhX7VMMwZTqb2sC4qFpKMG6AMY/dBixr6lsNXhp2ykyO69imALJ8qamFAvaAkaXKijoyazKP0kse2xI4l9XV4bCIgl2H0Wa628FuivmR7upw/nkgVzCDNBVakRjvcVypHWCaCRLZcllTSi7WQ4JFGziMXd1sL34Nq4zcWAK3gOH3aflgGD8razimKau3dA1gdKPVAGz4il2M5ImlunEbmK6S0uGZgh3NwKQsVTvB1M54/XjIF/sMvcUtDNYGtmrYRbrMdT2gApgSawNbNeH7y6gfiHJjNV7ctZpPE1jpZMA2cjFVpXtK0VFd4NVSYgmV9w5432XM3aV6wDYW76PYVjXFIF4uWo23CayRYMpLr0lUMLz/um0D5+EkRdtV7FgymltKlrZdYK3i9JnvUsriss1y35TZBg7k8oegQT85aLz0GcTkCThqbwPnguMHRSQ2+eQhZSRbcR+YvvqykzKi2EF74e2GsppEDxitcgvbUFZe5qohz5FqA1ftTA6oawxN+3D4M8j4bRxfGV7VNG0p+IdAPUzl15SRpBZwhf22YBJXWUEi6Ufnq8NqpXqHAqJYrJfcIzzQ+rgm8D5tJmWkSgxf/Lca0DqqD1z6jFfLTRLddBFrPpvBzCDt9GZqAFFifDaDJ3OneFPYt+VuHmL7wJNdaoqhbEqdT+wzCdWiCYxraCMpFD026yuYv9VhiLN0V4d/Gjd8jhs+xw2f44bP8b/AXypr8BPkxKYiAAAAAElFTkSuQmCC"
  }
}

export default {
  data () {
    return initialState();
  },
  components: {
    QrModel
  },
  created: function () {
    this.checkIfSessionIsStoredAndInit();
  },
  mounted: function () {
    $("#password-indicator-default").passwordStrength();
  },
  methods: {
    login() {
      let self = this;
      $("#" + this.loginBtn).addClass(this.loadingClass);
      $(".btn").attr("disabled", true);
      AxiosService.post(Constant.url.CREATE_USER, this.registerForm).then(
        (response) => {
          if (response.responseCode === "00" && response.responseData) {
            $.gritter.add({
              title: "Info",
              text: "Your access request has been submitted successfully.",
            });
            Object.assign(this.$data, initialState());
            this.$refs.observer.reset();
            this.refreshCaptcha();
            // self.qrCode = response.responseData.QrCodeImgData;
            // $('#modal-alert').appendTo("body").modal('show');
          } else {
            $.gritter.add({
              title: "Error",
              text: response.responseMsg,
            });
          }
          $("#" + this.loginBtn).removeClass(this.loadingClass);
          $(".btn").attr("disabled", false);
        }
      );
    },
    refreshCaptcha: function () {
      let self = this;
      AxiosService.get("captcha/refresh").then((response) => {
        self.captchaImg = response;
      });
    },
    init: async function () {
      let self = this;
      let enc = Utils.encryptStorage().getSession();
      AxiosService.get(Constant.url.GET_UI_CONFIF).then((response) => {
        enc.setItem(Constant.config.UI_CONFIG, response);
      });
      AxiosService.get("captcha/generate").then((response) => {
        self.captchaImg = response;
      });
    },

    checkIfSessionIsStoredAndInit() { 
      // if user visits signup page as a first page of all portal pages, we need to add server url to session to be able to generate various url properly
      let sessionServerUrl = sessionStorage.getItem(Constant.config.SERVER_URL);
      if(!sessionServerUrl) {
        fetch('/getEndpoint').then(response => response.json())
          .then( result => { 
            sessionStorage.setItem(Constant.config.SERVER_URL, result.backendURL);
            this.init();
          })
      }else{
        this.init();
      }
    }
  },
  beforeCreate: function () {},
};
</script>
<style scoped>
.content {
  margin-left: unset !important;
}
.card {
  position: absolute;
  width: 440px;
  height: 890px;
  left: calc(50% - 550px / 2);
  top: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 17, 51, 0.15);
  box-sizing: border-box;
  border-radius: 24px;
}
.card-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 17, 51, 0.6);
  margin-top: 22px !important;
}
.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  top: 10px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 17, 51, 0.8);
}
.ria-color {
  color: #ff6100;
}
.form-group {
  margin-bottom: 1rem;
  width: 80%;
  margin-left: 10%;
}

.login-policy {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(0, 17, 51, 0.6);
  margin-top: 40px !important;
}

.login-btn-disable {
  margin-left: 70%;
  background: rgba(0, 17, 51, 0.15);
  border-radius: 24px;
  color: rgba(0, 17, 51, 0.3);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 23%;
}
.login-btn-enable {
  margin-left: 70%;
  background: #ff6100;
  border-radius: 24px;
  color: rgb(255, 255, 255);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 23%;
}
a {
    color: #ff6100 !important;
    transition: color 100ms ease-in-out;
}
</style>