<template>
  <div id="content" class="content">
    <div class="card">
      <div class="card-block">
        <div v-if="this.sessionExpired">
          <h4 class="card-subtitle my-5 text-center" >
            Your session has expired. Please log in.
          </h4>
        </div>
        <div v-else>
          <h4 class="card-title text-center m-t-20">Welcome back!</h4>
          <h6 class="card-subtitle m-b-20 text-center" style="margin-right: 29%" >
            New at Dandelion?
            <span class="ria-color"
              ><a href="/magnolia/signup">Sign up</a></span
            >
          </h6>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="form-group m-b-20">
              <label for="userId" class="form-label">Email</label>
              <ValidationProvider
                name="Email"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="email"
                  v-model="loginForm.userId"
                  class="form-control form-control-lg"
                  id="userId"
                  :class="classes"
                  required
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group m-b-20">
              <InputPassword 
                v-model="loginForm.password" 
                v-on:inputVal="loginForm.password = $event.target.value"
              />
            </div>

            <div class="form-group m-b-20">
              <img :src="captchaImg" alt="" />
              <i
                class="fa fa-redo"
                style="font-size: 20px; margin-left: 2%; cursor: pointer"
                @click="refreshCaptcha()"
              ></i>
            </div>

            <div class="form-group m-b-20">
              <label for="captcha" class="form-label"
                >Enter characters above</label
              >
              <ValidationProvider
                name="characters"
                :rules="{ required: true }"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  v-model="loginForm.captcha"
                  class="form-control form-control-lg"
                  id="captcha"
                  required
                  :class="classes"
                  @keyup.enter="login()"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <h6 class="card-subtitle m-b-20 text-center ria-color">
              <router-link :to="{ name: 'resetPassword' }">
                Forgot your password?
              </router-link>
            </h6>

            <button
              type="button"
              class="btn"
              :disabled="invalid"
              :class="invalid ? 'login-btn-disable' : 'login-btn-enable'"
              @click="login()"
            >
              <i class="" id="loginBtnLbl"></i> &nbsp;&nbsp; Log in
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- end login-content -->
    <QrModel :qrCode="qrCode" :email="loginForm.userId" type="signin"></QrModel>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService";
import Constant from "../plugin/Constant";
import Utils from "../plugin/Utils";
import QrModel from "../components/QrModel.vue";
import InputPassword from "../components/InputPassword";
const uuidv4 = require('uuid/v4');
export default {
  name: "Login",
  data: function () {
    return {
      loginForm: {
        userId: "",
        password: "",
        captcha: "",
      },
      captchaImg: "",
      loadingClass: "fa fa-spinner fa-spin",
      loginBtn: "loginBtnLbl",
      qrCode: "",
      sessionExpired: false,
    };
  },
  components: {
    QrModel, InputPassword
  },
  props: ["config", "images"],
  created: function () {
    localStorage.setItem(Constant.config.METHOD_LIST, this.config.methodList);
    localStorage.setItem(Constant.config.MENU_LIST, this.config.startMenuName);
    localStorage.setItem(
      Constant.config.RIA_IMAGES,
      JSON.stringify(this.images)
    );
    sessionStorage.setItem(
    Constant.config.SESSION_EXPIRE_MINUTES, 
    this.config.sessionExpired ? this.config.sessionExpired : Constant.config.DEFAULT_SESSION_TIMEOUT);
    this.init();
  },
  methods: {
    login: function (e) {
      let self = this;
      $("#" + this.loginBtn).addClass(this.loadingClass);
      $(".btn").attr("disabled", true);
      AxiosService.post(Constant.url.LOGIN_USER, this.loginForm).then(
        (response) => {
          try {
          if (response.responseCode == "00") {
              /*$.gritter.add({
                title: "Success",
                text: "You have logged in successfully",
              });*/
              let enc = Utils.encryptStorage().getSession();
              enc.setItem(Constant.config.LOGIN_COOKIE, response.responseData);
              $("#modal-alert").appendTo("body").modal("show");
              if (_.has(response.responseData, "QrCodeImgData")) {
                self.qrCode = response.responseData.QrCodeImgData;
              }
            } else {
              $.gritter.add({
                title: "Error",
                text: response.responseMsg,
              });
            }
          }catch(error) {
            console.error(error);
          }
          $("#" + this.loginBtn).removeClass(this.loadingClass);
          $(".btn").attr("disabled", false);
        }
      );
    },
    refreshCaptcha: function () {
      let self = this;
      AxiosService.get("captcha/refresh").then((response) => {
        self.captchaImg = response;
      });
    },
    init: async function () {
      let self = this;
      let enc = Utils.encryptStorage().getSession();
      AxiosService.get(Constant.url.GET_UI_CONFIF).then((response) => {
        enc.setItem(Constant.config.UI_CONFIG, response);
      });
      AxiosService.get("captcha/generate").then((response) => {
        self.captchaImg = response;
      });
      sessionStorage.setItem(Constant.config.SEND_MONEY_APIS, this.config.sendMoneyApis);
    },
    strToUtf16Bytes(str) {
      const bytes = [];
      for (let ii = 0; ii < str.length; ii++) {
        const code = str.charCodeAt(ii); // x00-xFFFF
        bytes.push(code & 255, code >> 8); // low, high
      }
      return bytes;
    },
  },
  beforeCreate: async function () {
    //Utils.clearSession();
    sessionStorage.setItem(Constant.config.SESSION_ID, await uuidv4());
  },
  async mounted() {
      sessionStorage.setItem('DARK_MODE', "false");
      await swtichTheme.init();
      this.sessionExpired = this.$route.query.sessionExpired;
  },
};
</script>

<style scoped>
.content {
  margin-left: unset !important;
}
.card {
  position: absolute;
  width: 440px;
  height: 670px;
  left: calc(50% - 440px / 2);
  top: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 17, 51, 0.15);
  box-sizing: border-box;
  border-radius: 24px;
}
.card-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 17, 51, 0.6);
  margin-top: 22px !important;
}
.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  top: 10px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 17, 51, 0.8);
}
.ria-color {
  color: #ff6100;
}
.form-group {
  margin-bottom: 1rem;
  width: 80%;
  margin-left: 10%;
}

.login-policy {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(0, 17, 51, 0.6);
  margin-top: 40px !important;
}

.login-btn-disable {
  margin-left: 70%;
  background: rgba(0, 17, 51, 0.15);
  border-radius: 24px;
  color: rgba(0, 17, 51, 0.3);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 23%;
}
.login-btn-enable {
  margin-left: 70%;
  background: #ff6100;
  border-radius: 24px;
  color: rgb(255, 255, 255);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: 23%;
}
a {
  color: #ff6100 !important;
  transition: color 100ms ease-in-out;
}
</style>