import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import PageLoader from './helpers/PageLoader.vue';
import { ValidationObserver, ValidationProvider, extend, validate } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Constant from './plugin/Constant';
import Utils from './plugin/Utils';
import VueEvent from 'vue-event-handler';

Vue.config.productionTip = true;
Vue.use(VueRouter);
Vue.use(VueEvent);

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

const AdminLayout = () =>
    import ( /* webpackChunkName: "AdminLayout" */ './layout/Admin');
const DefaultLayout = () =>
    import ( /* webpackChunkName: "DefaultLayout" */ './layout/Default');
const ApiPage = () =>
    import ( /* webpackChunkName: "ApiPage" */ './api/ApiPage.vue');
const LoginPage = () =>
    import ( /* webpackChunkName: "LoginPage" */ './authenticate/Login.vue');
const DashboardPage = () =>
    import ( /* webpackChunkName: "DashboardPage" */ './dashboard/Dashboard');
const SignupPage = () =>
    import ( /* webpackChunkName: "SignupPage" */ './authenticate/Signup.vue');
const ChangePassword = () =>
    import ( /* webpackChunkName: "ChangePassword" */ './authenticate/ChangePassword.vue');
const ResetPassword = () =>
    import ( /* webpackChunkName: "ResetPassword" */ './authenticate/ResetPassword.vue');
const ProfilePage = () =>
    import ( /* webpackChunkName: "ProfilePage" */ './user/Profile.vue');
const ContactSupportPage = () =>
    import ( /* webpackChunkName: "ContactSupportPage" */ './pages/ContactSupportPage.vue');
const UpdateProfile = () =>
    import( /* webpackChunkName: "UpdateProfile" */ './user/UpdateProfile.vue');
const InvalidSubscription = () =>
    import( /* webpackChunkName: "InvalidSubscription" */ './authenticate/InvalidSubscription.vue');    


const router = new VueRouter({
    mode: 'history',
    base: '/magnolia',
    routes: [{ path: '*', component: PageLoader, meta: { layout: 'admin' } },
        {
            name: 'apiPage',
            path: '/apiPage',
            component: ApiPage,
            meta: { layout: 'admin' }
        },
        {
            name: 'login',
            path: '/login',
            component: LoginPage,
            meta: { layout: 'default' }
        },
        {
            name: 'dashboard',
            path: '/dashboard',
            component: DashboardPage,
            meta: { layout: 'admin' }
        },
        {
            name: 'signup',
            path: '/signup',
            component: SignupPage,
            meta: { layout: 'default' }
        },
        {
            name: 'invalidSubscription',
            path: '/invalidSubscription',
            component: InvalidSubscription,
            meta: { layout: 'default' }
        },
        {
            name: 'changePassword',
            path: '/changePassword',
            component: ChangePassword,
            meta: { layout: 'admin' }
        },
        {
            name: 'resetPassword',
            path: '/resetPassword',
            component: ResetPassword,
            meta: { layout: 'default' }
        },
        {
            name: 'profile',
            path: '/profile',
            component: ProfilePage,
            meta: { layout: 'admin' }
        },
        {
            name: 'contactSupportPage',
            path: '/contactSupportPage',
            component: ContactSupportPage,
            meta: { layout: 'admin' }
        },
        {
            name: 'updateProfile',
            path: '/updateProfile',
            component: UpdateProfile,
            meta: { layout: 'admin' }
        }
    ],
}, );

const landingPage = '/magnolia/ria-portal/login';
router.beforeEach((to, from, next) =>{
    if(!to.path.includes('/dummy')){
        next()
    }
})
router.beforeEach((to, from, next) => {
    try {
        if (to.meta != undefined) {
            if (to.path.includes('login') || to.path.includes('signup') || to.path.includes('resetPassword') || to.path.includes('invalidSubscription')) {
                to.meta.layout = 'default';
            } else {
                var userDetails = Utils.encryptStorage().getSession().getItem(Constant.config.USER_CONFIG);
                if (Utils.isEmpty(userDetails)) {
                    return next(landingPage);
                }
            }
        } else {
            return next(landingPage);
        }
    } catch (error) {
        console.error(error);
        return next(landingPage);
    }
    return next();
})


Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('admin-layout', AdminLayout);
Vue.component('default-layout', DefaultLayout);

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');